const env = {
  // SERVER: 'https://api.artichokeapp.com',
  SERVER: 'https://api.artichokeapp.com',
  // API_URL: 'https://api.artichokeapp.com/api',
  API_URL: 'https://api.artichokeapp.com/api',
  STRIPE: {
    PUBLIC_KEY:
      'pk_test_51M7zTzGZOzsN0UuRLyM3qnOwVAOLRNjLaURRpYfwFSyUsi6fKB7VNQTwCzsNBwwzorRBXRPXA5xRqRgg9Txt2NAf000HSNLkUC', // walletcampaigns
    CLIENT_ID: 'ca_Mrj5a8X6y0mveoxrFTkewDhfo7PYkhbJ', // walletcampaigns
  },
  G_MAP_API_KEY: 'AIzaSyC7Bj8PVfTnNIm8j2x7zEXT0-4bckwiM00',
}

export default env
